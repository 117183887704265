import React from 'react'
import styled from 'styled-components'
import { Box, Text } from '@rebass/grid'
import BlogImage from './BlogImage'
import BlogVideo from '../BlogVideo'

const BlogMedia = ({ data, ...rest }) => {
  const { video } = data

  return video ? <BlogVideo data={data} /> : <BlogImage data={data} {...rest} />
}
export default BlogMedia

import React, { useMemo } from 'react'
import styled from 'styled-components'
import { Box, Flex, Text } from '@rebass/grid'
import Image from '../../../../page-image-new'
import { GatsbyImage } from 'gatsby-plugin-image'
import { theme } from '../../../../../util/style'

const BlogImage = ({ data = {}, ...rest }) => {
  const { width = 1, justifyContent, alignItems, caption } = data

  return (
    <ImageWrap
      justifyContent={justifyContent}
      alignItems={alignItems}
      height={'100%'}
    >
      <Box width={[1, width]} as="figure" padding={0} margin={0} {...rest}>
        {data?.image && (
          <img
            style={{ width: '100%', height: 'auto' }}
            src={
              data?.image?.file?.path ||
              'http:' + data?.image?.file?.url + '?q=80&w=1500'
            }
            alt={caption || 'Happy Haus.'}
          />
        )}
        {caption && (
          <Figcaption>
            <Arrow>↑</Arrow> {caption}
          </Figcaption>
        )}
      </Box>
    </ImageWrap>
  )
}
export default BlogImage

const ImageWrap = styled(Flex)`
  img {
    display: block;
  }
`

const Figcaption = styled.figcaption`
  color: #888;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 17.5px */
  margin-top: 0.7em;
  left: 0;
  right: 0;
`

const Arrow = styled.span`
  font-family: ${theme.font.fontFamilyABCLight};
`

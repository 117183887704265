import React from 'react'

const IconPlay = ({ ...rest }) => {
  return (
    <svg
      width="34"
      height="40"
      viewBox="0 0 34 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...rest}
    >
      <path
        d="M34 20.0001L0.0952359 39.5751L0.0952376 0.425223L34 20.0001Z"
        fill="white"
      />
    </svg>
  )
}

export default IconPlay

import 'swiper/swiper.min.css'

import { Pagination, PaginationNext, PaginationPrev } from './Pagination'
import { Swiper, SwiperSlide } from 'swiper/react'

import { Box } from '@rebass/grid'
import React from 'react'
import styled from 'styled-components'
import { useState } from 'react'
import useWindowSize from '../../../../hooks/useWindowSize'

const BlogMediaCarousel = ({ images }) => {
  const [swiper, setSwiper] = useState(null)
  const [currentIndex, setCurrentIndex] = useState(0)
  const handleSlideChange = (swiper) => {
    setCurrentIndex(swiper.realIndex)
  }

  const { width } = useWindowSize()

  const isMobile = width < 800

  return (
    <Wrapper>
      <InnerWrapper>
        <PaginationPrev swiper={swiper} />
        <PaginationNext swiper={swiper} />
        <Swiper
          slidesPerView={'auto'}
          loop
          onSwiper={setSwiper}
          onSlideChange={handleSlideChange}
          spaceBetween={0}
          speed={800}
        >
          {images.map((image, index) => (
            <SwiperSlide key={index}>
              <img
                src={image.fluid.src}
                alt={`Slide ${index}`}
                style={{ objectFit: 'cover' }}
              />
            </SwiperSlide>
          ))}
        </Swiper>
        <Pagination
          images={images}
          currentIndex={currentIndex}
          swiper={swiper}
        />
      </InnerWrapper>
    </Wrapper>
  )
}
export default BlogMediaCarousel

const InnerWrapper = styled(Box)`
  position: relative;
  width: 67.5%;

  .swiper {
    width: 100%;
    height: 100%;
  }

  .swiper-slide {
    height: 100%;
    width: 100%;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }

  @media (max-width: 800px) {
    width: 100%;
  }
`

const Wrapper = styled(Box)`
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
`

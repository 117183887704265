import React from 'react'
import styled, { css } from 'styled-components'
import { Box } from '@rebass/grid'
import { fontLight, theme } from '../../../../util/style'

const BlogContent = ({ children, media }) => {
  return (
    <Wrapper px={[0, 2]} mt={[3, 0]} media={media}>
      <Copy align="left">{children}</Copy>
    </Wrapper>
  )
}
export default BlogContent

const Wrapper = styled(Box)`
  ${({ media }) =>
    media
      ? css`
          @media (max-width: 800px) {
            margin-top: 57px;
          }
        `
      : ``};
`
const Copy = styled(Box)`
  font-family: ${theme.font.fontFamilySerifThin};
  text-align: left;
  line-height: 130%;
  @media (max-width: 800px) {
    font-size: 14px;
  }

  & > :first-child {
    margin-top: 0;
  }

  u {
    text-decoration: underline;
    text-decoration-thickness: 1px;
  }

  h2,
  h3 {
    font-family: ${theme.font.fontFamilyMedium};
    line-height: 130%;
    font-weight: normal;
    margin-bottom: 25px;

    font-size: 20px;
    @media (max-width: 800px) {
      font-size: 19px;
      margin-bottom: 20px;
    }
    color: #31333a;

    letter-spacing: ${theme.letterSpacing(-3)};
  }

  p {
    margin-top: 0%;
    margin-bottom: 0.8rem;
    width: 100%;
    font-family: ${theme.font.fontFamilySerifThin};
    line-height: 140%;
    font-size: 22px;
    @media (max-width: 800px) {
      font-size: 18px;
    }
  }

  p:last-of-type {
    margin-bottom: 0;
  }

  p:last-child {
    margin-bottom: 0;
  }
  a {
    border-bottom: 1px solid ${theme.colors.grey11};
    color: ${theme.colors.grey1} !important;
    &:hover {
      border-bottom: 1px solid ${theme.colors.grey2};
    }
  }
`

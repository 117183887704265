import React from 'react'

const SvgBlogCarouselNext = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="44"
        height="44"
        rx="22"
        transform="matrix(1 -8.74228e-08 -8.74228e-08 -1 0 44)"
        fill="#4C4E55"
      />
      <path
        d="M21.4879 13.5898L29.7379 21.7498L11.5879 21.7498L11.5879 23.6098L29.7379 23.6098L21.4879 31.7998L23.8879 31.7998L33.0379 22.6798L23.8879 13.5898L21.4879 13.5898Z"
        fill="white"
      />
    </svg>
  )
}

export default SvgBlogCarouselNext

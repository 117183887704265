import React from 'react'

const SvgBlogCarouselPrev = () => {
  return (
    <svg
      width="44"
      height="44"
      viewBox="0 0 44 44"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="44"
        height="44"
        rx="22"
        transform="matrix(-1 0 0 1 44 0)"
        fill="#4C4E55"
      />
      <path
        d="M22.5121 30.4102L14.2621 22.2502H32.4121V20.3902H14.2621L22.5121 12.2002H20.1121L10.9621 21.3202L20.1121 30.4102H22.5121Z"
        fill="white"
      />
    </svg>
  )
}

export default SvgBlogCarouselPrev
